<template>
    <div style="width: 620px" v-if="!userImpersonation">
        <table style="font-size: 12px; width: 100%; overflow-x: scroll">
            <tr>
                <td>
                    <div v-for="acard in activePaymentInstruments" :key="acard.pit_instrument_id"
                        style="height: 0px; display: inline-flex;">
                        <div style="width: 120px; float: left; padding: 0 5px">
                            <label :for="`label${acard.pit_instrument_id}`">
                                <input type="radio" name="avcard" @change="handleCardSelection(acard)"
                                    :id="`label${acard.pit_instrument_id}`" />&nbsp;
                                <CreditCardLogos :ordinal="acard.pit_brand" :size="'40'" />
                                <br />
                                <b>{{ cardBrands(acard.pit_brand) }}</b><br />
                                ****{{ acard.pit_last_four }}<br />
                                {{ acard.pit_expiration }}
                            </label>
                            <RedX class="redx" :card="acard" @handleDeleteCard="handleDeleteCard(acard)"></RedX>
                        </div>
                    </div>
                </td>
            </tr>
        </table>
    </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from "vuex";
import CreditCardLogos from "@/components/CreditCardLogos.vue"
import RedX from "@/components/red-x.vue"
import { numericMixin } from "@/mixins/numericMixin"

export default {
    name: 'CreditCardsOnFile',
    mixins: [numericMixin],
    props: {
        PaymentInstruments: {
            type: Array,
            default: [],
        }
    },
    data() {
        return {
            removed: []
        }
    },
    components: {
        CreditCardLogos: CreditCardLogos,
        RedX: RedX
    },
    methods: {
        ...mapActions({
            removePaymentInstrument: 'gideonStore/removePaymentInstrument'
        }),
        handleCardSelection(acard) {
            this.$emit('handleCardSelection', acard)
        },
        handleDeleteCard(evt) {
            this.$swal({
                icon: 'question',
                showCancelButton: true,
                text: `Do you wish to delete this ${evt.pit_brand === 0 ? 'account' : 'card'}?  (****${evt.pit_last_four})`,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
            }).then(async (result) => {
                if (result.isConfirmed) {
                    await this.removePaymentInstrument(evt.pit_instrument_id).then((resp) => {
                        if (resp) {
                            this.removed.push(evt.pit_instrument_id)
                        } else {
                            this.$swal({
                                icon: 'error',
                                text: 'The attempt to delete the card/account failed.',
                                confirmButtonText: 'Ok'
                            }).then((result) => {
                                return
                            })
                        }
                    })
                }
            })
        }
    },
    computed: {
        ...mapGetters({
            userImpersonation: 'user/userImpersonation'
        }),
        activePaymentInstruments() {
            return this.PaymentInstruments.filter(flt => !this.removed.includes(flt.pit_instrument_id))
        }
    }
}
</script>

<style scoped>
.redx {
    position: relative;
    top: -2px;
    z-index: 200;
    cursor: pointer;
}
</style>
